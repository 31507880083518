/******** Theme Scripts *********/

// Get text color based on contrast
/**
 * Calculates the contrast color (black or white) based on the given hex color.
 *
 * @param {string} hexColor - The hex color code.
 * @returns {string} The contrast color ('black' or 'white').
 */
const getContrastYIQ = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

// Convert RGB to hex color
const rgbToHex = (rgb) => {
  const [r, g, b] = rgb.match(/\d+/g);
  const hex = ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  return `#${hex}`;
};


/**
 * Sets the text color based on the background color of the specified element.
 *
 * @param {HTMLElement} unique_id - The unique identifier of the element.
 * @returns {void}
 */
const setTextColorBasedOnBackground = (unique_id) => {
  // Set the color based on background if there is
  const app = unique_id;
  const color = app.style.backgroundColor;
  if (!color) {
    return;
  }
  const hexColor = rgbToHex(color);

  const textColor = getContrastYIQ(hexColor);
  // set all elements color inside the app
  const elements = app.querySelectorAll('*');
  // Array of exempt elements
  const exempt = ['button', 'a', 'input', 'select', 'textarea', 'h6'];
  elements.forEach(element => {
    if (exempt.includes(element.tagName.toLowerCase())) {
      return;
    }
    element.style.color = textColor;
  });
}

const columnModules = document.querySelectorAll('.column-module');
columnModules.forEach(columnModule => {
  setTextColorBasedOnBackground(columnModule);
});


/**
 * Represents a collection of accordion items.
 * @type {NodeList}
 */
const accordionItems = document.querySelectorAll('.accordion-item');
accordionItems.forEach(item => {
    item.addEventListener('click', function () {
        // Toggle active class on the clicked item
        item.classList.toggle('active');
        // Close other items
        accordionItems.forEach(otherItem => {
            if (otherItem !== item) {
                otherItem.classList.remove('active');
            }
        });
    });
});


/**
 * Represents a collection of swiper containers.
 * @type {NodeList}
 */
import Swiper from 'swiper';
const swiperTestimonial = document.querySelectorAll('.testimonial_slider .swiper-container');

// Loop through each selector and initialize Swiper
swiperTestimonial.forEach(selector => {
  const swiper = new Swiper(selector, {
    speed: 400,
    spaceBetween: 34,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },

    },
  });
});

const heroBannerSwiper = document.querySelectorAll('.hero-banner-swiper');
heroBannerSwiper.forEach(swiperElement => {
  const swiper = new Swiper(swiperElement, {
    speed: 400,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  });
});




/**
 * Represents a collection of tabbed sections.
 * @type {NodeList}
 */
const tabbedFAQ = document.querySelectorAll('.tabbed-section');
tabbedFAQ.forEach(tabbed => {
  const tabButtons = tabbed.querySelectorAll('.tab-btn');
  const tabContents = tabbed.querySelectorAll('.tab');

  tabButtons.forEach((button, index) => {
    button.addEventListener('click', () => {
      tabButtons.forEach((btn, i) => {
        btn.classList.remove('active');
        tabContents[i].classList.remove('active');
      });
      button.classList.add('active');
      tabContents[index].classList.add('active');
    });
  });
  // Set the first tab active
  tabButtons[0].click();
});


// // if #hero-banner is present on the page
// if (document.getElementById('hero-banner')) {
//   const modifier = 0.30;
//   /**
//    * Height of the background image.
//    * @type {number}
//    */
//   let bgImageHeight = document.querySelector('.background-image').offsetHeight;
//   // calculate the desired padding based on the modifier % of the image height
//   let desiredPadding = bgImageHeight * modifier;
//   // check if the desired padding is less than the minimum height
//   if (desiredPadding < 90) {
//     desiredPadding = Math.max(desiredPadding, 40); // Set minimum padding to 40px
//   }
//   // set the top and bottom padding of #hero-banner
//   document.getElementById('hero-banner').style.paddingTop = desiredPadding + 'px';
//   document.getElementById('hero-banner').style.paddingBottom = desiredPadding + 'px';
//   // change on resize
//   window.addEventListener('resize', function() {
//     bgImageHeight = document.querySelector('.background-image').offsetHeight;
//     desiredPadding = bgImageHeight * modifier;
//     if (desiredPadding < 90) {
//       desiredPadding = Math.max(desiredPadding, 40); // Set minimum padding to 40px
//     }
//     document.getElementById('hero-banner').style.paddingTop = desiredPadding + 'px';
//     document.getElementById('hero-banner').style.paddingBottom = desiredPadding + 'px';
//   });
// }

// Target the header element and add a class when the page is scrolled
/**
 * Represents the header element.
 * @type {HTMLElement}
 */
const header = document.querySelector('header');
window.addEventListener('scroll', function() {
  if (window.scrollY > 50) {
    header.classList.add('scrolled');
  } else {
    header.classList.remove('scrolled');
  }
});


